import React from 'react';

export default [
	{
		name: 'SIG Gramado',
		id: 'siggramado',
		content: (
			<>
				<p>
					O&nbsp;
					<a
						href="http://sig.gramado.rs.gov.br/geowise/login"
						target="_blank"
						rel="noopener noreferrer"
					>
						SIG Gramado
					</a>
					&nbsp;é composto por um software voltado à Gestão
					Territorial (disponível em&nbsp;
					<a
						href="http://sig.gramado.rs.gov.br/"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#0072ff' }}
					>
						http://sig.gramado.rs.gov.br/
					</a>
					) e um GeoPortal (disponível em&nbsp;
					<a
						href="http://geoportal.gramado.rs.gov.br/"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: '#0072ff' }}
					>
						http://geoportal.gramado.rs.gov.br/
					</a>
					), que apresentam customizações necessárias para atender às
					necessidades de manutenção e visualização integrada das
					bases de dados Geoespaciais do Cadastro Territorial do
					município de Gramado.
				</p>
				<p>
					Foi&nbsp;
					<a
						href="http://sig.gramado.rs.gov.br/geowise/login"
						target="_blank"
						rel="noopener noreferrer"
					>
					</a>
					&nbsp;desenvolvido nos anos de 2021 e 2023 junto à empresa
					ENGEFOTO ENGENHARIA E AEROLEVANTAMENTO S.A..
				</p>
				<p>
					O &nbsp;
					<a
						href="http://sig.gramado.rs.gov.br"
						target="_blank"
						rel="noopener noreferrer"
					>
						SIG Gramado
					</a>
					&nbsp;é composto por Módulos Urbano, 
					due permite a manutenção do Cadastro Imobiliário, de Logradouros, 
					de Zoneamentos, de Parcelas Territoriais, de Unidades Autônomas, 
					de Infraestrutura Urbana, de Avaliações e Auditoria. 
					O sistema é utilizado e gerido por grande parte das secretarias e órgãos municipais.&nbsp;
				</p>

				<p>
					Dentre várias funcionalidades para uso interno da
					prefeitura, o sistema também fornece serviços ao cidadão,
					através do GeoPortal, objetivando desburocratização: como
					consultas de viabilidade automatizadas, emissão de certidões
					e outros documentos oficiais. Além disso, é um instrumento
					de transparência pública da gestão municipal.
				</p>
				<p>
					O &nbsp;
					<a
						href="http://sig.gramado.rs.gov.br/geowise/login"
						target="_blank"
						rel="noopener noreferrer"
					>
						SIG Gramado
					</a>
					&nbsp;, é baseado em tecnologias livres, contém mapas
					temáticos, ortofotos e ortomosaicos integrados com dados
					vetoriais e alfanuméricos. Permite a manutenção da base
					cartográfica oficial do município, sincronizada com base de
					dados alfanumérica, mantendo a integridade e qualidade dos
					dados disponíveis. Além disso, o sistema permite realizar o
					cadastro de objetos territoriais, feições, análises
					espaciais e geração de mapas para impressão.
				</p>

				<p>
					<strong>Sobre o Sistema</strong>
				</p>

				<p>
					O projeto&nbsp;
					<a
						href="http://sig.gramado.rs.gov.br/geowise/login"
						target="_blank"
						rel="noopener noreferrer"
					>
						SIG Gramado
					</a>
					&nbsp;utiliza as linguagens de back-end denominada Ruby,
					associada ao framework Ruby on Rails, e recursos de
					programação front-end HTML5, CSS, Java Script, ExtJS e Open
					Layers e como ambiente de desenvolvimento foi utilizado o
					Microsoft Visual Studio Code, para a ferramenta de cadastro.
				</p>

				<p>
					<strong>Características técnicas da plataforma Web</strong>
				</p>

				<p>Sistema Operacional (SO): Linux Ubuntu Server 18.04 LTS.</p>

				<p>Software de Geoprocessamento Web: Geoserver</p>

				<p>Software de Geoprocessamento Desktop: QGIS</p>

				<p>
					Gerenciador de Banco de Dados: software PostgreSQL® com o
					PostGIS®
				</p>

				<p>Software de Banco de Dados: PG Admin IV e DBeaver</p>

				<p>Banco de Dados: PostgreSQL</p>

				<p>Extensão Espacial: PostGIS</p>

				<p>
					Browser Suportados: Microsoft Edge 15, Google Chrome build
					60.0.3112.112, Mozilla Firefox 54, Safari 11.0.3 ou
					superiores.
				</p>
			</>
		),
	}
	
];
