import React from "react"
import { Alert, Button, ButtonGroup, Input } from "reactstrap"
import { Container } from "./styles"

const Alerts = (props) => {

    function setTerm() {
        localStorage.setItem('termgeo', true);
        window.location.reload()
    }

    function add_line_break(text, limit) {
        let newText = '';
        for (let i = 0; i < text.length; i++) {
          newText += text[i];
          if ((i + 1) % limit === 0) {
            newText += '<br />';
          }
        }
        return newText;
      }

  const bodyText = props.textBody
    return (
        <>
            <div>
                <Alert color={props.color}>
                    <h4 className="alert-heading">
                    {props.title || 'Alert'}
                    </h4>
                    <p>
                 
                    <div
                        style={{
                        width: '100%',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                        fontFamily: 'Arial, sans-serif', 
                        fontSize: '14px', 
                        lineHeight: '1.5'
                        }}
                    >
                        {bodyText.split('\n\n').map((section, index) => {
                        const title = section.split(': ')[0] ? add_line_break(section.split(': ')[0], 95) : '';
                        const content = section.split(': ')[1] ? add_line_break(section.split(': ')[1], 100) : '';
                        return (
                        <div key={index}>
                            <span style={{ fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: title }}></span>
                            <span style={{ display: 'block', textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: content }}></span>
                            <br />
                        </div>
                        
                        );
                    })}
                    </div>
                    </p>
                    <hr />
                    <Container>
                    <ButtonGroup className="d-flex justify-content-center">
                        <Button
                        className='btn btn-success'
                        onClick={() => { props.function || setTerm() }}
                        >
                        {props.textButton || 'Aceitar'}
                        </Button>
                    </ButtonGroup>
                    {'  '}
                    </Container>
                </Alert>
            </div>
        </>


    )
}

export default Alerts