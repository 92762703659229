export const emailInfos = [
	{ subject: 'Geoportal', recipient: 'contato@gramado.rs.gov.br' },
	{
		subject:
			'Região continental - Pré-consulta de viabilidade para instalação',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{
		subject: 'Região continental - Pré-consulta para fins de construção',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{
		subject: 'Pré-consulta de viabilidade para instalação',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{
		subject: 'Pré-consulta para fins de construção',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{
		subject: 'Pré-consulta ambiental',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{
		subject: 'Certidão de confrontantes',
		recipient: 'contato@gramado.rs.gov.br',
	},
	{ subject: 'Download', recipient: 'contato@gramado.rs.gov.br' },
	{ subject: 'Geoserviços', recipient: 'contato@gramado.rs.gov.br' },
	{ subject: 'Outros', recipient: 'contato@gramado.rs.gov.br' },
];
