import React from 'react';
import { Button } from 'reactstrap';

import { ButtonGroup } from './styles';

function FileDownload({ data }) {
	return (
		<tr>
			<td className="p-2 w-100 align-middle font-weight-bold">
				{data.name}
			</td>
			<td className="p-2 align-middle">
				<ButtonGroup>
					{data.files.map((e) => (
						<Button
							tag="a"
							key={String(e.id)}
							size="sm"
							color="info"
							target="_blank"
							href={e.url}
							download={e.originalName}
							title={e.originalName}
						>
							{`${e.ext.toUpperCase()} ${
								e.description ? `(${e.description})` : ''
							}`}
						</Button>
					))}
				</ButtonGroup>
			</td>
		</tr>
	);
}

export default FileDownload;