import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import FormAcompanharAnalise from './FormAcompanharAnalise';
import FormIniciarAnalise from './FormIniciarAnalise';

import { Aside, Content, Item } from './styles';

const Project = () => {
	
	const { project } = useParams();
	const history = useHistory();

	const [selectedOption, setSelectedOption] = useState('monitor-analyzes');

	useEffect(() => {
		if (project) {
			setSelectedOption(project);
		}
	}, [project]);

	return (
		<>
			<Content>
				<Aside>
						<h3>Documentos</h3>
						<Item
								selected={selectedOption === 'analysis'}
								onClick={() => setSelectedOption('analysis')}
						>
							Iniciar Análise
						</Item>
						<Item
								selected={selectedOption === 'monitor-analyzes'}
								onClick={() => setSelectedOption('monitor-analyzes')}
						>
							Acompanhar Análise
						</Item>
				</Aside>

				{selectedOption === 'monitor-analyzes' ? (
					<FormAcompanharAnalise />
				) : (
					<FormIniciarAnalise />
				)}
			</Content>
			<Footer />
    </>
	);
};

export default Project;
