import React, { useState, useEffect } from 'react';
import NewsCards from '~/Components/NewsCards';
import ArticleCards from '~/Components/ArticleCards';
import Footer from '~/Components/Footer';
import { Title } from './styles';
import Alerts from '~/Components/Alert';
import termoDeUso from '../termoDeUso';

const Home = () => {
	const [user, setUser] = useState(null);
	useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);

	return (
		<>
		{
			 localStorage.getItem('termgeo')!=='true'? 
			 <Alerts color={'info'}
			 title={'Termo de Uso de dados Geoespaciais'}
			 textBody={termoDeUso}
					 
			 />
			 : ''			
		}
			<Title>
				<h1>SEJA BEM VINDO, {user ? user.name : ''}!</h1>
				<p>
					O Geoportal é uma plataforma do projeto SIG Gramado e permite
					acesso à Infraestrutura de Dados Espaciais – IDE da
					Prefeitura Municipal de Gramado.
				</p>
				<p>
					Aqui você encontra a emissão de documentos automatizados,
					geoserviços, dados para download, metadados, tutorial de
					uso, documentação técnica, notícias, entre outras
					funcionalidades.
				</p>
			</Title>
			<br />
			<NewsCards />
			<br />
			<br />
			<br />
			<ArticleCards />
			<Footer />
			
		</>
	);
};

export default Home;
