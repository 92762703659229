import styled from 'styled-components';


export const Box = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
    margin-right: 10px;
    width: 400px; 
    border-left: ${(props) => (props.status === 1 ? '5px solid green' : '5px solid yellow')};
`;


export const Button = styled.button`
    padding: 5px 10px;
    background-color: #61a0e5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

export const StatusText = styled.p`
    color: black;
`;
