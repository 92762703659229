/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { FiArrowRightCircle, FiArrowLeftCircle } from 'react-icons/fi';

import { Container, Content, ArticleCard, Image, Scroller } from './styles';

import news1 from '~/assets/img/news_1.png';
import news2 from '~/assets/img/news_2.png';
import news3 from '~/assets/img/news_3.png';
import news4 from '~/assets/img/news_4.png';
import news5 from '~/assets/img/news_5.png';


const ArticleCards = () => {
	const content = useRef(null);

	function scrollTo(element, to, duration) {
		if (duration <= 0) return;
		const difference = to - element.scrollLeft;
		const perTick = (difference / duration) * 10;

		setTimeout(() => {
			element.scrollLeft += perTick;
			if (element.scrollLeft === to) return;
			scrollTo(element, to, duration - 10);
		}, 10);
	}

	function handleScroll(isRight) {
		const { current } = content;
		if (isRight) {
			scrollTo(current, current.scrollLeft - 200, 100);
		} else {
			scrollTo(current, current.scrollLeft + 200, 100);
		}
	}

	return (
		<Container>
			<Scroller>
				<div onClick={() => handleScroll(true)}>
					<FiArrowLeftCircle size={25} />
				</div>
			</Scroller>
			<Scroller right>
				<div onClick={() => handleScroll()}>
					<FiArrowRightCircle size={25} />
				</div>
			</Scroller>
			<Content ref={content}>
			
				<ArticleCard className="container">
					<Image url={news1}>
						<a href="https://www.gramado.rs.gov.br/noticias/geral/prefeitura-faz-mapeamento-e-recadastramento-imobiliario-em-gramado1887">
							<h3>
								Prefeitura faz mapeamento e recadastramento imobiliário em Gramado.
							</h3>
						</a>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news2}>
						<a href="https://www.gramado.rs.gov.br/noticias/geral/prefeitura-de-gramado-e-ministerio-publico-trabalham-na-regularizacao-de-loteamentos">
							<h3>
								PMG e Ministério Público trabalham na regularização de loteamentos
							</h3>
						</a>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news3}>
						<a href="https://www.gramado.rs.gov.br/noticias/geral/interesses-ambientais-sociais-e-culturais-fazem-parte-do-novo-plano-diretor706">
							<h3>
								Interesses ambientais, sociais e culturais fazem parte do novo Plano Diretor
							</h3>
						</a>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news4}>
						<a href="https://www.gramado.rs.gov.br/noticias/geral/conselhos-municipais-passam-por-qualificacao-sobre-a-aplicacao-do-novo-plano-diretor">
							<h3>
								Conselhos Municipais passam por qualificação sobre a aplicação do novo plano diretor
							</h3>
						</a>
					</Image>
				</ArticleCard>
				<ArticleCard className="container">
					<Image url={news5}>
						<a href="https://www.gramado.rs.gov.br/noticias/geral/regularizacao-concluida-a-fase-de-cadastro-dos-moradores-do-loteamento-imetil-e-travessa-pvc">
							<h3>
								Regularização: Concluída a fase de cadastro dos moradores do Loteamento Imetil e Travessa PVC
							</h3>
						</a>
					</Image>
				</ArticleCard>
			</Content>
		</Container>
	);
};

export default ArticleCards;
