import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
import * as Validation from 'availity-reactstrap-validation';
import * as Reactstrap from 'reactstrap';
import Footer from '~/Components/Footer';
import * as Styles from './styles';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const { data, done } = useSelector((e) => e.search);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    login: '',
    password: '',
    errorMessage: '',
    successMessage: '',
    user: null
  });

  const handleLogin = async () => {
    setLoading(true);
    try {
      if (!recaptchaToken) {
        throw new Error('Por favor, complete o reCAPTCHA');
      }
      setState({ ...state, errorMessage: '', successMessage: '', loading: true });

      const response = await axios.post(process.env.REACT_APP_GEOWISE_HOST + 'geoportal/login', { ...state, recaptchaToken });
      const { data } = response;

      if (data.user) {
        localStorage.setItem('user', JSON.stringify(data.user));
        setState({ ...state, successMessage: 'Logado com sucesso', errorMessage: '', loading: false, user: data.user });
      } else {
        setState({ ...state, errorMessage: 'E-mail ou senha inválidos', successMessage: '', loading: false });
      }
    } catch (error) {
      setState({ ...state, errorMessage: 'Erro ao fazer login. Por favor, tente novamente mais tarde.', successMessage: '', loading: false });
    }
  };
  
   // Recaptcha
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
      {state.user && <Redirect to="/" />}
      <Styles.Content>
        <Styles.Box>
          <Styles.LoginFormContainer>
            <Styles.Logo src="/images/client_logo_gramado.png" alt="Logo da empresa" />
            <Validation.AvForm
              onValidSubmit={handleLogin}
              style={{ paddingTop: data[0] && done ? '20px' : '0' }}
            >
              <Styles.ContactTitle>
                <h2>LOGIN GEOPORTAL</h2>
              </Styles.ContactTitle>
              <Validation.AvGroup>
                <Reactstrap.Row form>
                  <Reactstrap.Col md={12}>
                    <Validation.AvGroup>
                      <Reactstrap.Input
                        type="text"
                        disabled={loading}
                        placeholder="Usuário"
                        value={state.login}
                        onChange={({ target: { value } }) =>
                          setState({ ...state, login: value })
                        }
                      />
                      <Validation.AvInput
                        required
                        type="hidden"
                        name="completeName"
                        id="completeNameInput"
                        value={state.login}
                      />
                      <Validation.AvFeedback>
                        Este campo é obrigatório
                      </Validation.AvFeedback>
                    </Validation.AvGroup>
                  </Reactstrap.Col>
                </Reactstrap.Row>
              </Validation.AvGroup>
              <Reactstrap.Row form>
                <Reactstrap.Col md={12}>
                  <Validation.AvGroup>
                    <Reactstrap.Input
                      type="password"
                      disabled={loading}
                      placeholder="Senha"
                      value={state.password}
                      onChange={({ target: { value } }) =>
                        setState({ ...state, password: value })
                      }
                    />
                    <Validation.AvInput
                      required
                      type="hidden"
                      name="completeName"
                      id="completeNameInput"
                      value={state.password}
                    />
                    <Validation.AvFeedback>
                      Este campo é obrigatório
                    </Validation.AvFeedback>
                  </Validation.AvGroup>
                </Reactstrap.Col>
              </Reactstrap.Row>
              {state.successMessage && (
                <Styles.FeedbackMessage type="success">
                  {state.successMessage}
                </Styles.FeedbackMessage>
              )}
              {state.errorMessage && (
                <Styles.FeedbackMessage type="error">
                  {state.errorMessage}
                </Styles.FeedbackMessage>
              )}

              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA}
                onChange={handleRecaptcha}
              />

              <Styles.BtnCont>
                <Styles.ButtonSend
                  color="info"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <VscLoading size={25} color="#fff" />
                  ) : (
                    'Entrar'
                  )}
                </Styles.ButtonSend>
              </Styles.BtnCont>
            </Validation.AvForm>
            <Link to="/register">Não possui uma conta?</Link>
            <Link to="/forgot_password">Esqueci minha senha!</Link>
          </Styles.LoginFormContainer>
        </Styles.Box>
      </Styles.Content>
      <Footer />
    </>
  );
};

export default Login;