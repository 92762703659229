import React, { useState, useEffect } from 'react';
import utils from '../../utils';

import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import {
	Container,
	Header,
	Logo,
	LogoContainer,
	Menu,
	MenuItem,
	MenuContainer,
	RespContainer,
	MenuIcon,
	NavbarContainer,
	UserMenuButton,
	ProfilePic,
	DropdownMenu,
	MenuItemDiv

} from './styles';

import defaultProfilePic from '../../assets/img/user.png';

const Navbar = () => {
	const history = useHistory();

	const { path } = useRouteMatch();

	const [menuOpened, setMenuOpened] = useState(false);

	const [user, setUser] = useState(null);

	useEffect(() => {
		const storedUser = JSON.parse(localStorage.getItem('user'));
		if (storedUser) {
		setUser(storedUser);
		}
	}, []);

	const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

	const handleLogout = async () => {
    try {
			localStorage.removeItem('user');
			history.push('/login');
    } catch (error) {
        console.error('Erro ao deslogar:', error);
    }
	};

	return (
		<Container>
			<Header>
				<RespContainer>
					<LogoContainer onClick={() => history.push('/')}>
						<Logo />
						<span>Geoportal</span>
					</LogoContainer>
					<MenuIcon onClick={() => setMenuOpened(!menuOpened)} />
				</RespContainer>

				<MenuContainer>
					<Menu opened={menuOpened}>
						<MenuItem active={path === '/' || path === '/article'}>
							<Link to="/">Início</Link>
						</MenuItem>
						<MenuItem active={path === '/map'}>
							<Link to="/map">Mapas Interativos</Link>
						</MenuItem>
						<MenuItem
							active={
								path === '/downloads' ||
								path.includes('/downloads/')
							}
						>
							<Link to="/downloads">Download</Link>
						</MenuItem>
						<MenuItem  active={path === '/services'}>
							<Link to="/services">Emissão de Documentos</Link>
						</MenuItem> 
						<MenuItem active={path === '/help'}>
							<Link to="/help">Ajuda</Link>
						</MenuItem>
						{ user ? 
							<NavbarContainer>
							<UserMenuButton onClick={toggleMenu}>
								<ProfilePic src={defaultProfilePic} alt="Profile" />
								
							</UserMenuButton>
							{isMenuOpen && (
								<DropdownMenu>
									<MenuItemDiv>
										{user.name}
									</MenuItemDiv>
									<MenuItemDiv>
										{user.email}
									</MenuItemDiv>
									<MenuItemDiv onClick={handleLogout}>
										Sair
									</MenuItemDiv>
								</DropdownMenu>
							)}
						</NavbarContainer>
						: 
						<MenuItem active={path === '/login'}>
							{utils.viewLoginEnabled ? <Link to="/login">Login</Link> : null}
						</MenuItem>
						}
					</Menu>
					
				</MenuContainer>
			</Header>
		</Container>
	);
};

export default Navbar;
