import React, { useState } from 'react';
import { Label, FormGroup } from 'reactstrap';

function FileUploadDropdown({ onFileSelect }) {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    onFileSelect(files);
  };

  return (
    <div>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
      />

      <FormGroup>
        <Label htmlFor="usos">
          Arquivos selecionados:
        </Label>
      </FormGroup>
      <ul>
        {selectedFiles.map((file, index) => (
          <li key={index}>
            {file.name} - {file.size} bytes
          </li>
        ))}
      </ul>
    </div>
  );
}

export default FileUploadDropdown;