import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import Footer from '../../Components/Footer';
import FormDeclaracaoMunicipal from './FormDeclaracaoMunicipal';
import termoDeUso from '../termoDeUso';
import Alerts from '~/Components/Alert';
import { Aside, Content, Item } from './styles';

const Service = () => {
	const { service } = useParams();
	const history = useHistory();

	const [selectedOption, setSelectedOption] = useState('declaracao-municipal');

	useEffect(() => {
		if (service) {
			setSelectedOption(service);
		}
	}, [service]);

	return (
		<>
			{
				localStorage.getItem('termgeo')!=='true'? 
				<Alerts color={'info'}
				title={'Termo de Uso de dados Geoespaciais'}
				textBody={termoDeUso}
						
				/>
				:
				<Content>
				<Aside>
					<h3>Documentos</h3>
						<>
							<Item
								selected={
									selectedOption === 'declaracao-municipal'
								}
								onClick={() =>
									history.push(
										'/services/declaracao-municipal'
									)
								}
							>
								Declaração Municipal
							</Item>

						</>
				</Aside>
				{selectedOption === 'declaracao-municipal' && (
					<FormDeclaracaoMunicipal />
				)}
			</Content>
			}
			
			<Footer />
		</>
	);
};

export default Service;
