import styled from 'styled-components';

export const SearchBarContainer = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
`;

export const SearchButton = styled.button`
    padding: 8px 15px;
    background-color: #007C00;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
`;