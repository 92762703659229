import styled, { css } from 'styled-components';
import { MdMenu } from 'react-icons/md';
import logo from '../../assets/img/logo-gramado.png';

import { DISPLAY_WIDTH } from '~/config/styles';

export const Container = styled.nav`
	box-shadow: 0px 7px 20px -6px rgba(0, 0, 0, 0.5);
	width: 100%;
	background-color: #ffff;
	position: relative;
	z-index: 999;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-wrap: wrap;
		padding: 5px 17px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		padding: 8px 20px;
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;
	z-index: 100;
`;

export const LogoContainer = styled.div`
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-right: 10px;
	cursor: pointer;
	user-select: none;

	span {
		font-family: 'Roboto', sans-serif;
		color: #002f66;
		-webkit-font-smoothing: antialiased;

		@media (max-width: ${DISPLAY_WIDTH}) {
			font-size: 13px;
			margin-top: -6px;
		}

		@media (min-width: ${DISPLAY_WIDTH}) {
			font-size: 15px;
			margin-top: -8px;
		}
	}
`;

export const Logo = styled.img.attrs({
	src: logo,
})`
	cursor: pointer;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 130px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 160px;
	}
`;

export const Menu = styled.ul`
	width: 100%;
	display: flex;
	overflow: hidden;
	transition: background-color 200ms ease-in-out;
	background-color: #fff;
	z-index: 100;
	margin: 0;

	@media (max-width: ${DISPLAY_WIDTH}) {
		flex-direction: column;
		justify-content: center;
		height: 0px;
		position: absolute;
		left: 0;
		padding: 0px;
		box-shadow: 0px 10px 10px -6px rgba(0, 0, 0, 0.4);
		transition: height 200ms ease-in-out, padding 200ms ease-in-out,
			box-shadow 200ms ease-in-out;

		${(p) =>
			p.opened &&
			css`
				height: 212px !important;
				padding: 10px;
				transition: height 200ms ease-in-out, padding 200ms ease-in-out,
					box-shadow 200ms ease-in-out;
			`}
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		flex-direction: row;
		/* justify-content: flex-start; */
		justify-content: flex-end;
	}
`;

export const MenuContainer = styled.div`
	width: 100%;
	display: flex;
`;

export const RespContainer = styled.div`
	@media (max-width: ${DISPLAY_WIDTH}) {
		min-width: 100%;
		display: flex;
		justify-content: space-between;
	}
`;

export const MenuItem = styled.li`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px 10px;
	transform: scale(1);
	transition: background-color 250ms ease-in-out, transform 250ms ease-in-out,
		box-shadow 250ms ease-in-out;

	@media (max-width: ${DISPLAY_WIDTH}) {
		cursor: pointer;

		&:hover {
			background-color: #fdae45;
			transform: scale(1.01);
			box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
			transition: background-color 250ms ease-in-out,
				transform 250ms ease-in-out, box-shadow 250ms ease-in-out;

			a {
				color: #02734A;
				transition: color 200ms ease-in-out;
			}
		}
	}

	a {
		text-decoration: none;
		color: ${(p) => (p.active ? '#02734A' : '#000000')};
		text-align: center;
		padding: 2px;
		border-bottom: 2px solid transparent;
		transition: border-bottom 150ms ease-in-out, color 200ms ease-in-out;

		${({ highlight, active }) =>
			highlight &&
			!active &&
			css`
				border-bottom: 2px solid #139dd1;
				background-color: rgb(19, 157, 209, 0.07);
				transition: border-bottom 150ms ease-in-out;
			`}

		&:hover {
			@media (min-width: ${DISPLAY_WIDTH}) {
				background-color: transparent;
				border-bottom: 2px solid #fdae45;
				transition: border-bottom 150ms ease-in-out,
					background-color 150ms ease-in-out;
			}
		}
	}
`;

export const MenuIcon = styled(MdMenu).attrs({
	size: 40,
	color: '#00B9F0',
})`
	cursor: pointer;

	@media (max-width: ${DISPLAY_WIDTH}) {
		display: block;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		display: none;
	}
`;



export const NavbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const UserMenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  margin-right: 20px; /* Ajuste conforme necessário */
`;

export const ProfilePic = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
	border-radius: 4px;
	padding: 8px;
	z-index: 100;
	justify-content: flex-end;
	display: 'flex';
`;


export const DropdownContent = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 8px;
	z-index: 100;
	justify-content: flex-end;
	display: 'flex';
`;

export const MenuItemDiv = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.span`
  font-weight: bold;
`;

export const UserEmail = styled.span`
  font-size: 12px;
  color: #777;
`;