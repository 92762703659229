/* eslint-disable no-underscore-dangle */
import { all, takeLatest, call, put } from 'redux-saga/effects';
import urljoin from 'url-join';
import { v4 as uuidv4 } from 'uuid';
import { host } from '~/config/api';

import api from '~/services/api';
import { LOAD_REQUEST } from './constants';

import getToken from '../auth';

import { loadSuccess, loadFailure } from './actions';

export function* load() {
	try {
		yield call(getToken);

		const { data } = yield api.get(
			'geoportal/downloads/get_downloads_geoportal'
		);

/* 		const { data: dataMap } = yield api.get(
			'geowise/mapa/get_map_by_target',
			{
				params: { target: 'geoportal' },
			}
		);

		const mapDownloads = [];
		dataMap.content.forEach((e) => {
			if (
				e.type.toUpperCase() !== 'WMS' &&
				e.type.toUpperCase() !== 'WFS'
			)
				return undefined;
			e.layers.forEach((f) => {
				mapDownloads.push({
					files: [
						{
							id: uuidv4(),
							ext: 'SHP',
							url: urljoin(
								e.source,
								`/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${e.workspace}:${f.layer}&outputFormat=SHAPE-ZIP`
							),
						},
						{
							id: uuidv4(),
							ext: 'METADADOS',
							url: urljoin(
								e.source,
								`/rest/workspaces/${e.workspace}/featuretypes/${f.layer}.xml`
							),
						},
					],
					name: `${e.name} - ${f.name}`,
				});
			});
			return undefined;
		}); */

		data.downloads.forEach((e) => {
			e.id = uuidv4();
			e.route = e.description
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.replace(/\s/g, '-')
				.toLowerCase();
			e.downloads.forEach((d) => {
				d.id = uuidv4();
				d.files.forEach((f) => {
					f.id = uuidv4();
					f.url = urljoin(host, f.url);
				});
			});
		});

		const res = [
			/* {
				id: 'map_data_download',
				description: 'Camadas em SIG do mapa',
				route: 'camadas-em-sig-do-mapa',
				complement:
					'Na Camadas em SIG do mapa estão disponíveis para download os arquivos cartográficos vetoriais da base de dados espaciais utilizados no mapa interativo do Geoportal',
				downloads: mapDownloads,
			}, */
			...data.downloads,
		];

		yield put(loadSuccess(res));
	} catch (error) {
		yield put(loadFailure());
	}
}

export default all([takeLatest(LOAD_REQUEST, load)]);
