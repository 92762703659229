import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, MiniCards, CardsTitle, Content } from './styles';
import NewsCard from '../NewsCard';
import utils from '../../utils';

const NewsCards = () => {
	const history = useHistory();

	return (
		<Container>
			<CardsTitle>ACESSE</CardsTitle>
			<Content>
				<MiniCards>
					<NewsCard
						color="#02734A"
						text={['MAPA', 'INTERATIVO']}
						onClick={() => history.push('/map')}
					/>
					<NewsCard
						color="#579CD9"
						text={['EMISSÃO DE', 'DOCUMENTOS']}
						onClick={() => history.push('/services')}
					/>
					<NewsCard
						color="#02734A"
						text={['DOWNLOADS']}
						onClick={() => history.push('/downloads')}
					/>
					{utils.viewProjectLoginEnabled && (
						<NewsCard
							color="#579CD9"
							text={['ANALISE DE', 'PROJETOS']}
							onClick={() => history.push('/projects')}
						/>
					)}
				</MiniCards>
			</Content>
		</Container>
	);
};

export default NewsCards;