import React, { useState } from 'react';
import { SearchBarContainer, SearchButton } from './style';
import InputMask from 'react-input-mask';

const SearchBar = ({ onSearch }) => {
    const [query, setQuery] = useState('');

    const handleChange = (event) => {
        setQuery(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        onSearch(query);
    };

    return (
        <SearchBarContainer>
            <form onSubmit={handleSubmit}>
                <InputMask
                    type="text"
                    mask="99.99.999.9999.999-999"
                    placeholder="Pesquisar..."
                    value={query}
                    onChange={handleChange}
                    style={{
                        padding: '8px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        marginRight: '5px'
                    }}
                />
                <SearchButton type="submit">Pesquisar</SearchButton>
            </form>
        </SearchBarContainer>
    );
};

export default SearchBar;