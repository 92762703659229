import React, { useState, useEffect } from 'react';
import { Jumbotron, Form } from 'reactstrap';

import SearchBar from '~/Components/SearchBar';
import Documents from '~/Components/Documents';
import axios from 'axios';
import { Section } from '../styles';
function FormAcompanharAnalise() {
	const [documents, setDocuments] = useState([]);
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	
	useEffect(() => {
		const storedUser = JSON.parse(localStorage.getItem('user'));
		if (storedUser) {
			setUser(storedUser);
		} else {
			setIsLoading(false);
		}
	}, []);
	
	useEffect(() => {
		
		if (user && documents.length === 0) {
			async function getDocuments() {
				const user_id = user.id;
				try {
					const response = await axios.get(process.env.REACT_APP_GEOWISE_HOST + 'geoportal/analise_projetos/get_track_analysis', {
						headers: {
							'Content-Type': 'application/json',
						},
						params: {
							user_id
						}
					});
					if (response.data) {
						setDocuments(response.data.analise_projetos);
					}
				} catch (error) {
					console.error('Erro ao obter documentos:', error);
				}
			}
			
			getDocuments();
		}
	}, [user]);


	const handleSearch = async (query) => { 
		if (user) {
			const user_id = user.id;
	
			try {
				const response = await axios.get(process.env.REACT_APP_GEOWISE_HOST + 'geoportal/analise_projetos/get_analysis', {
					headers: {
						'Content-Type': 'application/json',
					},
					params: {
						user_id,
						query
					}
				});
				if (response.data) {
					setDocuments(response.data.analise_projetos);
				}
			} catch (error) {
				console.error('Erro ao obter documentos:', error);
			}
		}
	};

	return (
		<>
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<div>
						<h1>Lista de Protocolos</h1>
					</div>
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}/>
					
					<div>
						<SearchBar onSearch={handleSearch} />
					</div>
				
					<div>
						<Documents documents={documents} />
					</div>
				</Jumbotron>
			</Section>
		</>
	);
}

export default FormAcompanharAnalise;
