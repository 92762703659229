import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Input, FormGroup, Label, Button, Jumbotron, Form } from 'reactstrap';
import CustomSelect from '~/Components/CustomSelect';
import Waiter from '~/Components/Waiter';
import { Section } from '../styles';
import axios from 'axios';
import FileUploadDropdown from '~/Components/FileUploadDropdown';
import { Redirect } from 'react-router-dom';
import utils from '../../../utils';

const FormIniciarAnalise = () => {
	const [loading, setLoading] = useState(false);
	const [inscricao, setInscricao] = useState('');
	const [tipo, setType] = useState([]);
	const [erro, setErro] = useState('');
	const [message, setMessage] = useState('');
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [sucesso, setSucesso] = useState(false);

	useEffect(() => {
			const storedUser = JSON.parse(localStorage.getItem('user'));
			if (storedUser) {
					setUser(storedUser);
			}
			setIsLoading(false);
	}, []);

	if (isLoading) {
		return <div>Carregando...</div>;
	}

	if (!user && utils.viewLoginEnabled) {
		return <Redirect to="/login" />;
	}

	const handleFileSelect = (files) => {
		setSelectedFiles(files);
	};

	async function gerarAnalise() {

		try {
			setLoading(true);
			const filesData = new FormData();
			selectedFiles.forEach((file, index) => {
				filesData.append(`file_${index}`, file);
			});
			const { data } = await axios.post(process.env.REACT_APP_GEOWISE_HOST + 'geoportal/analise_projetos/record_analysis', filesData, {
				params: {
					tipo,
					user,
					inscricao
				},
			})
			if(!data.success){
				setErro(data?.message || 'Houve um erro ao gerar a análise')
			} else {
				setErro('')
				setMessage(data.message)
				setSucesso(true);
				setLoading(false)
				setSelectedFiles([]);
				setType([]);
				setInscricao('');
				return <Redirect to="/projets/analysis" />; 
		}
		setLoading(false)
		} catch (error) {
			if (error.isAxiosError) {
				setErro(
					error.response.data.message ||
						'Houve um erro ao gerar análise'
				);
			} else {
				setErro('Informações da consulta não encontradas.');
			}
			setLoading(false);
		}
	}

	return (
		<>
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">
						Análise de Projetos
					</h1>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					{sucesso && <p style={{ color: '#17b35a' }}>{message}</p>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="inscricao_imobiliaria">
								Inscrição imobiliária
							</Label>
							<InputMask
								type="text"
								mask="99.99.999.9999.999-999"
								className="form-control"
								name="inscricao_imobiliaria"
								id="inscricao_imobiliaria"
								placeholder="Ex: 12.34.567.8910.111-213"
								maskChar="_"
								value={inscricao}
								onChange={({ target: { value } }) =>
									setInscricao(value)
								}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="usos">
								Tipo de Análise
							</Label>
							<CustomSelect
								value={tipo}
								onChange={(e) => setType(e)}
								placeholder="Selecione o tipo de análise"
								isSearchable={false}
								isMulti={false}
								options={[
									{ label: "Projeto", value: "projeto" },
									{ label: "Regularização", value: "regularizacao" }
								]}
							/>
						</FormGroup>

						<FormGroup>
							<Label htmlFor="usos">
								Anexar arquivos
							</Label>

							<FileUploadDropdown onFileSelect={handleFileSelect} />
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarAnalise}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
			{loading && <Waiter />}
		</>
	);
}

export default FormIniciarAnalise;
