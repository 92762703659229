import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { VscLoading } from 'react-icons/vsc';
import * as Validation from 'availity-reactstrap-validation';
import * as Reactstrap from 'reactstrap';
import Footer from '~/Components/Footer';
import * as Styles from './styles';
import { Redirect } from 'react-router-dom';
import axios from 'axios';

const Register = () => {
  const { data, done } = useSelector((e) => e.search);
  const { loading, success, error } = useSelector((e) => e.help);
  const [passwordError, setPasswordError] = useState('');
  const [state_register, setStateRegister] = useState({
    name: '',
    document: '',
    phone: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    errorMessage: '',
    successMessage: '',
    registered: false 
  });

  const handleRegister = async () => {
    if (state_register.password !== state_register.passwordConfirmation) {
      setPasswordError('As senhas não coincidem');
      return;
    } else {
      setPasswordError('');
    }

    try {
      setStateRegister(prevState => ({ ...prevState, errorMessage: '', successMessage: '', loading: true })); 

      const response = await axios.post(process.env.REACT_APP_GEOWISE_HOST + 'geoportal', { ...state_register });
      const { data } = response;

      if (data.success) {
        setStateRegister(prevState => ({ ...prevState, successMessage: 'Registrado com sucesso', errorMessage: '', loading: false, registered: true })); 
      } else {
        setStateRegister(prevState => ({ ...prevState, errorMessage: data.message, successMessage: '', loading: false }));
      }
    } catch (error) {
      setStateRegister(prevState => ({ ...prevState, errorMessage: 'Erro ao registrar. Por favor, tente novamente mais tarde.', successMessage: '', loading: false })); 
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setStateRegister({ ...state_register, [name]: value });
  };


	return (
		<>
      {state_register.registered && <Redirect to="/login" />}
      <Styles.Content>
        <Styles.Box>
          <Styles.LoginFormContainer>
            <Styles.Logo src="/images/client_logo_gramado.png" alt="Logo da empresa" />
            <Validation.AvForm
              onValidSubmit={handleRegister}
              style={{ paddingTop: data[0] && done ? '20px' : '0' }}
            >
              <Styles.ContactTitle>
                <h2>CADASTRO GEOPORTAL</h2>
              </Styles.ContactTitle>
              {!success && !error ? (
                <>
                  <Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="text"
                          disabled={loading}
                          placeholder="Nome"
                          name="name"
                          value={state_register.name}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>
                  <Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="text"
                          disabled={loading}
                          placeholder="CPF"
                          name="document"
                          value={state_register.document}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>
                  <Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="text"
                          disabled={loading}
                          placeholder="Telefone"
                          name="phone"
                          value={state_register.phone}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>
                  <Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="text"
                          disabled={loading}
                          placeholder="E-mail"
                          name="email"
                          value={state_register.email}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>
									<Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="password"
                          disabled={loading}
                          placeholder="Senha"
                          name="password"
                          value={state_register.password}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>

                  <Reactstrap.Row form>
                    <Reactstrap.Col md={12}>
                      <Validation.AvGroup>
                        <Reactstrap.Input
                          type="password"
                          disabled={loading}
                          placeholder="Confirmar Senha"
                          name="passwordConfirmation"
                          value={state_register.passwordConfirmation}
                          onChange={handleChange}
                        />
                        <Validation.AvFeedback>
                          Este campo é obrigatório
                        </Validation.AvFeedback>
                        {passwordError && (
                          <Styles.FeedbackMessage type="error">
                            {passwordError}
                          </Styles.FeedbackMessage>
                        )}
                      </Validation.AvGroup>
                    </Reactstrap.Col>
                  </Reactstrap.Row>
                </>
              ) : (
                <Styles.FeedbackMessage type={success ? 'success' : 'error'}>
                  {success
                    ? 'Registrado com sucesso'
                    : 'Erro ao registrar. Por favor, tente novamente mais tarde.'}
                </Styles.FeedbackMessage>
              )}

							{state_register.successMessage && (
                <Styles.FeedbackMessage type="success">
                  {state_register.successMessage}
                </Styles.FeedbackMessage>
              )}
              {state_register.errorMessage && (
                <Styles.FeedbackMessage type="error">
                  {state_register.errorMessage}
                </Styles.FeedbackMessage>
              )}

              <Styles.BtnCont>
                <Styles.ButtonSend
                  color="info"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <VscLoading size={25} color="#fff" />
                  ) : (
                    'CADASTRAR'
                  )}
                </Styles.ButtonSend>
              </Styles.BtnCont>
            </Validation.AvForm>
          </Styles.LoginFormContainer>
        </Styles.Box>
      </Styles.Content>
      <Footer />
    </>
  );
};

export default Register;