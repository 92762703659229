import React from 'react';
import { Box, Button, StatusText} from './styles';
import { FaCheckCircle, FaHourglassHalf } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const Documents = ({ documents }) => {
  
  const handleDownload = async (documents) => {
    const zip = new JSZip();
  
    try {
      const promises = documents.map(async (document, index) => {
        const url = document.url.replace(/ /g, '%20');
        const response = await fetch(process.env.REACT_APP_GEOWISE_HOST + url);
        const blob = await response.blob();
        zip.file(`documento_${index + 1}.${document.original_name.split('.').pop()}`, blob);
      });
  
      await Promise.all(promises);
  
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, 'documentos.zip');
      });
    } catch (error) {
      console.error('Erro ao baixar e compactar documentos:', error);
    }
  };
  function capitalizeFirstLetter(string) {
    const word = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    return word === 'Regularizacao' ? 'Regularização' : word;
  }

    return (
      <div style={{ display: 'flex' }}>
        {documents.map((document) => (
          <Box key={document.id} status={document.project.fields['status']}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {document.project.fields['status'] === '1' ? (
                  <FaCheckCircle color="green" size={24} />
              ) : (
                  <FaHourglassHalf color="yellow" size={24} />
              )}
              <div style={{ marginTop: '5px'}}>
                  <StatusText>{document.project.fields['status'] === '1' ? 'Concluído' : 'Em análise'}</StatusText>
              </div>
            </div>
            <div style={{ marginLeft: '15px' }}>
              <StatusText>Protocolo: {document.project.fields['protocolo']}</StatusText>
              <StatusText>Inscrição: {document.project.inscricao}</StatusText>
              <StatusText>Tipo de análise: {capitalizeFirstLetter(document.project.fields['tipo'])}</StatusText>
              <StatusText>Parecer: {document.project.fields['observacao'].length === 0 ? 'Aguardando análise': document.project.fields['observacao']}</StatusText>
              <Button onClick={() => handleDownload(document.documents)}>Baixar Documento</Button>
              

            </div>
          </Box>
        ))}
      </div>
    );
};

export default Documents;