require('dotenv').config();

export const host = process.env.REACT_APP_GEOWISE_HOST;
export const login = process.env.REACT_APP_GEOWISE_LOGIN;
export const password = process.env.REACT_APP_GEOWISE_PASS;
export const geowise_token = process.env.REACT_APP_GEOWISE_AUTH_TOKEN;


export const loginGeoserver = process.env.REACT_APP_GEOSERVER_LOGIN;
export const passwordGeoserver = process.env.REACT_APP_GEOSERVER_PASS;
export const recaptcha = process.env.RECAPTCHA;
export const viewLoginEnabled = process.env.REACT_APP_VIEW_LOGIN_ENABLED;
export const viewProjectEnabled = process.env.REACT_APP_VIEW_PROJECT_ENABLED;


