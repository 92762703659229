export default [
	{ label: 'Início', link: '/' },
	{ label: 'Mapas interativos', link: '/map' },
	{ label: 'Emissão de documentos', link: '/services' },
	{ label: 'Downloads', link: '/downloads' },
	{
		label: 'Download mapa Físico Político',
		link: '/downloads/mapa_fisico_politico',
	},
	{ label: 'Download Ambiental', link: '/downloads/ambiental' },
	{ label: 'Download Cadastro', link: '/downloads/cadastro' },
	{ label: 'Download CENSO IBGE 2010', link: '/downloads/senso_ibge_2010' },
	{
		label: 'Download Limites políticos',
		link: '/downloads/limites_politicos',
	},
	{ label: 'Download Área urbanizada', link: '/downloads/area_urbanizada' },

	{
		label: 'Emissão de Documentos - Pré-Consulta para Fins de Construção',
		link: '/services/consulta-construcao',
	},
	{
		label: 'Emissão de Documentos - Pré-Consulta Ambiental',
		link: '/services/consulta-ambiental',
	},
	{
		label: 'Emissão de Documentos - Memorial Descritivo',
		link: '/services/memorial-descritivo',
	},
	{
		label: 'Emissão de Documentos - Planta Quadra',
		link: '/services/planta-quadra',
	},
	{
		label: 'Emissão de Documentos - Declaração Municipal',
		link: '/services/declaracao-municipal',
	},
	{
		label:
			'Emissão de Documentos - Pré-consulta de Viabilidade para Instalação',
		link: '/services/consulta-instalacao',
	},

	{
		label: 'Artigos',
		link: '/article',
	},
	{
		label: 'Artigo GeoFloripa',
		link: '/article/geofloripa',
	},
	{
		label: 'Artigo Diretoria de Gestão Territorial',
		link: '/article/diretoria_gestão_territorial',
	},
	{
		label: 'Artigo Comissão Municipal de Geoinformação',
		link: '/article/comissao_municipal_geoinformacao',
	},
	{
		label: 'Artigo História do Geoprocessamento',
		link: '/article/historia_do_geoprocessamento',
	},
	{
		label: 'Geoserviços',
		link: '/geoservices',
	},
	{
		label: 'Documentos - Iniciar Análise',
		link: '/projects/analysis',
	},
	{
		label: 'Documentos - Acompanhar Análise',
		link: '/projects/monitor-analyzes',
	},
];
