import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label, Button, Jumbotron, Form, Input } from 'reactstrap';

import { Section } from '../styles';
import Waiter from '~/Components/Waiter';
import api, { signIn } from '../../../services/api';
import PDFModal from '~/Components/PDFModal';

function FormDeclaracaoMunicipal() {
	const [modalVisivel, setModalVisivel] = useState(false);
	const [loading, setLoading] = useState(false);
	const [erro, setErro] = useState('');
	const [pdfUrl, setPdfURL] = useState('')

	const [tipo, setTipo] = useState('');
  const [valor, setValor] = useState('');

  const handleSelecionarTipo = (e) => {
    setTipo(e.target.value);
    setValor('');
  };

	async function gerarConsulta() {
		try {
			await signIn();
			setLoading(true);
			const { data } = await api.post('/urbano/relatorios/declaracao_municipal',{
				valor, tipo,
			})

			if(!data?.success || !data?.report_response?.pdf_url){
				setErro(data?.message || 'Houve um erro ao gerar documento')
			}

			setPdfURL(data.report_response.pdf_url)
			setLoading(false)
			setModalVisivel(true)
			setErro('')
		} catch (error) {
			setLoading(false)
			setErro('Houve um erro ao gerar documento')
		}
	}

	function closeModal() {
		setModalVisivel(false);
	}

	return (
		<>
			{modalVisivel && (
				<PDFModal
					title='Declaração Municipal'
					closeModal={closeModal}
					pdfUrl={pdfUrl}
				/>
			)}

			{loading && <Waiter />}
			<Section>
				<Jumbotron style={{ background: 'transparent' }}>
					<h1 className="display-6">Declaração Municipal</h1>
					<p className="lead">Secretaria Municipal do Planejamento, Urbanismo e Publicidade</p>
					{erro && <span style={{ color: '#ff1912' }}>{erro}</span>}
					<hr className="my-2" />
					<br />
					<Form onSubmit={(e) => e.preventDefault()}>
						<FormGroup>
							<Label htmlFor="Tipo">Selecione o tipo:</Label>
							<Input
								type="select"
								name="Tipo"
								id="Tipo"
								value={tipo}
								onChange={handleSelecionarTipo}
							>
								<option value="">Selecione...</option>
								<option value="codigo">Código</option>
								<option value="matricula">Matrícula</option>
								<option value="inscricao">Inscrição Municipal</option>
							</Input>
						</FormGroup>
						<FormGroup>
							{tipo === 'codigo' && (
								<div>
									<Label htmlFor="codigo">Código:</Label>
									<Input
										type="text"
										className="form-control"
										name="codigo"
										id="codigo"
										placeholder="Digite o código"
										value={valor}
										onChange={(e) => setValor(e.target.value)}
									/>
								</div>
							)}
							{tipo === 'matricula' && (
								<div>
									<Label htmlFor="matricula">Matrícula:</Label>
									<Input
										type="text"
										className="form-control"
										name="matricula"
										id="matricula"
										placeholder="Digite a matrícula"
										value={valor}
										onChange={(e) => setValor(e.target.value)}
									/>
								</div>
							)}
							{tipo === 'inscricao' && (
								<div>
									<Label htmlFor="inscricao">Inscrição Municipal:</Label>
									<InputMask
										type="text"
										mask="99.99.999.9999.9999"
										className="form-control"
										name="inscricao"
										id="inscricao"
										maskChar=""
										placeholder="Ex: 12.34.567.8910.1112"
										value={valor}
										onChange={(e) => setValor(e.target.value)}
									/>
								</div>
							)}
						</FormGroup>
						<FormGroup>
							<Button
								onClick={gerarConsulta}
								color="info"
								type="submit"
							>
								Gerar
							</Button>
						</FormGroup>
					</Form>
				</Jumbotron>
			</Section>
		</>
	);
}

export default FormDeclaracaoMunicipal;
