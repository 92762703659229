import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import {
	FaFacebookSquare,
	FaInstagram,
	FaYoutube,
} from 'react-icons/fa';
import { BsTwitterX } from "react-icons/bs";

import { Container, Item, FollowUs, ImgLink } from './styles';
import logo_prefeitura from '~/assets/img/logo-footer.png';

const Footer = () => {
	return (
		<Container>
			<Row>
				<Col md="5">
					<Item className="mb-3">
						<h3>PREFEITURA MUNICIPAL DE GRAMADO</h3>
						<a
							href="https://www.gramado.rs.gov.br/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Site da prefeitura
						</a>
						<a
							href="https://www.gramado.rs.gov.br/cidade/conheca-gramado"
							target="_blank"
							rel="noopener noreferrer"
						>
							Conheça Gramado
						</a>
						<a
							href="https://www.gramado.rs.gov.br/secretarias"
							target="_blank"
							rel="noopener noreferrer"
						>
							Secretarias
						</a>

						<a
							href="https://www.gramado.rs.gov.br/servicos"
							target="_blank"
							rel="noopener noreferrer"
						>
							Serviços
						</a>
						<a
							href="https://e-gov.betha.com.br/transparencia/01037-158/recursos.faces?mun=9bLe8KbFHR0="
							target="_blank"
							rel="noopener noreferrer"
						>
							Transparência
						</a>
						<a
							href="https://eouve.com.br/#"
							target="_blank"
							rel="noopener noreferrer"
						>
							Ouvidoria
						</a>
					</Item>
				</Col>
				<Col md="3">
					<Item className="mb-3">
						<h3>SIG GRAMADO</h3>
						<a
							href="/article/siggramado"
							target="_blank"
							rel="noopener noreferrer"
						>
							Histórico
						</a>
						<a
							href="/article/siggramado"
							target="_blank"
							rel="noopener noreferrer"
						>
							Estrutura
						</a>
					</Item>
					<Item className="mb-3">
						<h3>AJUDA</h3>
						<Link to="/help">Fale conosco</Link>
						<Link to="/help">Endereço</Link>
						<Link to="/help">Horários</Link>
						<Link to="/help">Contato</Link>
					</Item>
				</Col>
				<Col md="4">
					<Item className="mb-3">
						<h3>SIGA-NOS</h3>
						<FollowUs>
							<ImgLink
								href="https://www.gramado.rs.gov.br/"
								target="_blank"
								rel="noopener noreferrer"
								alt="Prefeitura Municipal de Gramado"
								title="Prefeitura Municipal de Gramado"
								img={{
									src: logo_prefeitura,
									size: '100%',
								}}
							/>
							<a
								href="https://www.facebook.com/prefeituradegramado/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaFacebookSquare size={24} />
							</a>
							<a
								href="https://www.instagram.com/prefeituradegramado/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaInstagram size={24} />
							</a>
							<a
								href="https://www.youtube.com/@prefeituradegramado3480"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FaYoutube size={24} />
							</a>
							<a
								href="https://twitter.com/cidadedegramado"
								target="_blank"
								rel="noopener noreferrer"
							>
								<BsTwitterX size={24} />
							</a>
						</FollowUs>
					</Item>
				</Col>
			</Row>
		</Container>
	);
};
export default Footer;
