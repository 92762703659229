const termoDeUso = `
O SIG Gramado é o sistema computacional responsável pelo acesso às bases de dados geoespaciais centralizadas, produzidas e/ou adquiridas pelas diversas secretarias deste Município. Os mapas por ele gerados têm caráter meramente consultivo/ilustrativo, não dispensam a realização de levantamento topográfico por profissional da área devidamente habilitado. Não possuem precisão posicional de um levantamento topográfico/documental individual de cada lote. Resultam de trabalhos de levantamento, de métodos e recursos diferentes, em períodos distintos, bem como ajustes topológicos. Por isso, são utilizados como um indicativo posicional.

A utilização deste sistema está condicionado à aceitação dos termos de uso, sendo eles:

- Ciência de que os dados publicados/disponibilizados neste sistema são um conjunto geoespacial, proveniente de diversas secretarias e em constante desenvolvimento. Sendo que, a qualquer tempo, podem ser corrigidos, ajustados ou ocultados, em especial quando se constate algum erro ou inconsistência;

- Os dados publicados/disponibilizados neste sistema não podem ser utilizados de forma a violar a legislação vigente, em especial a Lei Geral de Proteção de Dados (LGPT) e a lei de Direitos Autorais;

- Os dados publicados/disponibilizados neste sistema não podem ser comercializados ou locados, total ou parcialmente. Sendo que, se reproduzidos e/ou republicados, deve haver a citação de sua procedência/fonte;

- A utilização dos dados publicados/disponibilizados neste sistema não garante a prévia legalidade de seu emprego. Nem tampouco, podem ser utilizados em processos administrativos ou judiciais contra a administração municipal de Gramado/RS;

- O Município de Gramado/RS, bem como qualquer órgão ou ente desta administração municipal, não poderá ser responsabilizado por erros contidos neste sistema, bem como de seu mau uso;

- Em caso de descumprimento dos termos acima, o usuário do sistema e/ou de seus dados não tem mais o direito de utilizá-los, nem seus derivados.

Data de última atualização 25/04/2024.`;

export default termoDeUso;