import styled from 'styled-components';

import bg from '../../assets/img/bg.png';

export const Container = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-image: url(${bg});
	padding-top: ${(p) => (p.noPadding ? 0 : '20px')};
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 150%;
`;
