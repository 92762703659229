import styled, { keyframes, css } from 'styled-components';
import { Row, Button, FormGroup, Input } from 'reactstrap';

const DISPLAY_WIDTH = '630px';

const spin = keyframes`
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
`;

export const Content = styled.div`
	flex: 1;
	transition: width ease-in-out 200ms;
	display: flex;
	background-color: #ffff;
	padding: 20px;
	flex-direction: column;
	border-radius: 8px;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 100%;
		flex-direction: column;
		transition: width ease-in-out 200ms;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 35%;
		transition: width ease-in-out 200ms;
	}
`;

export const LoginFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

// Estilizando o logo
export const Logo = styled.img`
    width: 100px; /* ajuste o tamanho conforme necessário */
    height: auto;
    margin-bottom: 20px;
`;

export const Box = styled.div`
	margin-top: 20px;
	border: 1px solid #ced4da;
	padding: 10px;
`;

export const ResultsTitle = styled.p`
	font-weight: 600;
	font-size: 1.1rem;
	text-align: center;
	padding: 10px 10px 20px 10px;
	border-bottom: 1px solid #ced4da;
`;

export const ContactTitle = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;

	p {
		font-weight: 600;
		font-size: 1.1rem;
		text-align: center;
		margin: 0;
	}
`;

export const ContactContent = styled(Row)`
	.item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		max-width: 200px;
		margin-bottom: 10px;

		p {
			margin-bottom: 7px;
			font-weight: 600;
			text-align: center;
		}

		a {
			word-break: break-all;
			width: 100%;
			text-align: center;
		}

		span {
			word-break: break-all;
			width: 100%;
			text-align: center;
			color: #1a1a1a;
		}
	}
`;


export const ButtonSend = styled(Button)`
	position: relative;

	div {
		${({ disabled }) =>
			disabled
				? css`
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: rgb(31, 155, 207, 0.7);

						svg {
							animation: ${spin} 1.5s infinite linear;
							color: #fff !important;
						}
				  `
				: css`
						display: none;
				  `}
	}
`;

export const FeedbackMessage = styled.div`
	font-weight: 600;
	width: 100%;
	text-align: center;
	padding: 10px;
	margin-bottom: 20px;

	${({ type }) =>
		type === 'success'
			? css`
					color: #00bfa5;
			  `
			: css`
					color: #ff5252;
			  `}
`;

export const BtnCont = styled(FormGroup)`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const LinkCancel = styled.p`
	margin: 0;
	cursor: pointer;
	margin-top: 5px;

	&:hover {
		text-decoration: underline;
	}
`;

export const InputSearch = styled(Input)`
	text-align: center;

	&::-webkit-input-placeholder {
		text-align: center;
	}
`;

export const Accordeon = styled.div``;

export const AccordeonItem = styled.div``;

export const TableWithin = styled.table`
	td,
	th {
		border: 1px solid #8492a6;
		padding: 2px 7px;
		text-align: center;
		vertical-align: middle;
	}
`;
