import styled, { css } from 'styled-components';

import bg from '../../assets/img/bg.png';

const DISPLAY_WIDTH = '630px';

export const Container = styled.div`
	flex: 1;
	overflow: auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-image: url(${bg});
	padding-top: 20px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
`;

export const Aside = styled.aside`
	background-color: #eee;
	height: max-content;
	display: flex;
	flex-direction: column;

	h3 {
		font-weight: 600;
		font-size: 1rem;
		white-space: nowrap;
		padding: 20px 20px 20px 20px;
		cursor: default;
		opacity: 0.5;
	}

	@media (max-width: ${DISPLAY_WIDTH}) {
		margin-top: 20px;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		margin-right: 3px;
	}
`;

export const Title = styled.div`
	padding: 20px;
	margin-bottom: 20px;
	background-color: rgba(0, 0, 0, 0.5);
	color: #ffff;
	cursor: default;

	h1 {
		margin-bottom: 10px;
		font-size: 22pt;
	}
`;

export const Content = styled.div`
	height: max-content;
	transition: width ease-in-out 200ms;
	display: flex;

	@media (max-width: ${DISPLAY_WIDTH}) {
		width: 100%;
		flex-direction: column-reverse;
		transition: width ease-in-out 200ms;
	}

	@media (min-width: ${DISPLAY_WIDTH}) {
		width: 80%;
		transition: width ease-in-out 200ms;
	}
`;

export const Header = styled.header`
	color: #2c354a;
	margin-bottom: 20px;

	h1 {
		font-size: 1.5rem;
		padding-right: 20px;
	}

	span {
		font-size: 8pt;
	}
`;

export const Section = styled.section`
	text-indent: 10pt;
	background-color: #eee;
	padding: 20px;
	flex: 1;

	p {
		font-size: 1.05rem;
		font-weight: 300;
		margin-bottom: 10px;
		color: #333333;
	}
`;

export const Item = styled.div`
	text-decoration: none;
	color: #002f66;
	text-align: center;
	padding: 10px 20px;
	border-bottom: 2px solid transparent;
	font-size: 0.9rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	transition: border-bottom 150ms ease-in-out, transform 150ms ease-in-out,
		background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;

	@media (min-width: ${DISPLAY_WIDTH}) {
		max-width: 200px;
	}

	${(p) =>
		p.selected &&
		css`
			background-color: #fdae45;
			box-shadow: 0px 8px 20px -6px rgba(0, 0, 0, 0.5);
			transform: scale(1.05);
			transition: border-bottom 150ms ease-in-out,
				transform 150ms ease-in-out, background-color 150ms ease-in-out,
				box-shadow 150ms ease-in-out;
		`}

	&:hover {
		background-color: #fdae45;
		box-shadow: 0px 8px 20px -6px rgba(0, 0, 0, 0.5);
		transform: scale(1.05);
		transition: border-bottom 150ms ease-in-out, transform 150ms ease-in-out,
			background-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
	}
`;

export const TextArea = styled.div`
	p {
		text-align: justify;
	}

	strong {
		font-weight: 600 !important;
	}
`;
